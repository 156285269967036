/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.scss in this case)
import './styles/app.scss';
// import './styles/bootstrap.min.css';
import './styles/styleCarou.css';

import '../templates/shared/macro_outils.scss';
import '../templates/shared/macro_navbarAdminEtFront.scss';
import '../templates/contact/contact.scss';

// start the Stimulus application
import './bootstrap';

//$form.attr('action') c'est <form action="/lien"
//requête ajax en lien avec produitType et l'évent listener
// qu'il y a sur produitType
function produitTypeEvent(idChamp) {
    $(document).on('change', idChamp, function () {
        let el = document.getElementsByClassName('d-none');
        if (el[0] != undefined) {
            el[0].classList.remove("d-none");
        }
        let $field = $(this)
        let $form = $field.closest('form')
        let data = {}
        data[$field.attr('name')] = $field.val()
        $.post($form.attr('action'), data).then(function (data) {
            let $input = $(data).find('#produit_type')
            $('#produit_type').replaceWith($input)
        })
    })
}

//dans modifier le produit sur le site sur l'admin :
// récupère toutes les id générées par le form de symfony pour
// les catégories et les passe dans la fonction produitTypeEvent
var modifierProduitsCategEl = document.querySelectorAll('[id^="produit_categorie_"]');

modifierProduitsCategEl.forEach(element => {
    produitTypeEvent('#' + element.id);
});



// produitTypeEvent('#produit_categorie_1');
// produitTypeEvent('#produit_categorie_2');


// $(document).on('change', '#produit_categorie_1', function() {
//     let $field = $(this)
//     let $form = $field.closest('form')
//     let data = {}
//     data[$field.attr('name')] = $field.val()
//     $.post($form.attr('action'), data).then(function (data) {
//         let $input = $(data).find('#produit_type')
//         $('#produit_type').replaceWith($input)
//     })
// })

// $(document).on('change', '#produit_categorie_2', function() {
//     let $field = $(this)
//     let $form = $field.closest('form')
//     let data = {}
//     data[$field.attr('name')] = $field.val()
//     $.post($form.attr('action'), data).then(function (data) {
//         let $input = $(data).find('#produit_type')
//         $('#produit_type').replaceWith($input)
//     })
// })